
































// Vue
import Vue from "vue";
import { mapGetters } from "vuex";
import store from "@/core/services/store";
// DayPilot Scheduler
import { DayPilot } from "daypilot-pro-vue";
import Scheduler from "@/views/dispatch/jobs/scheduler/Scheduler.vue";
import UpdateJobDialog from "./job-dashboard/_dialogs/UpdateJobDialog.vue";
// moment.js
import moment from "moment";
// PNotify
import { error } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import * as Confirm from "@pnotify/confirm";
// Models
import { IJob } from "@/core/_models/job.model";
import { IUser } from "@/core/_models/user.model";
import { IDriver } from "@/core/_models/driver.model";
import { IApplication } from "@/core/_models/application.model";
import { ScheduleEvent } from "./scheduler/_models/scheduleEvent.model";
import { ScheduleResource } from "./scheduler/_models/scheduleResource.model";
import { JobSchedulerSelection } from "./scheduler/_models/jobSchedulerSelection.model";
// Services
import JobsService from "./Jobs.service";

export default Vue.extend({
  name: "JobScheduler",
  components: { Scheduler, UpdateJobDialog },

  data() {
    return {
      isLoading: false,

      lastUsedDate: new Date().toISOString() as string,
      jobsData: [] as IJob[],

      //#region Schedule Jobs
      selectedDriverDateRange: undefined as JobSchedulerSelection | undefined,
      drivers: null as IDriver[] | null,
      showJobDialog: false,
      selectedJob: undefined as IJob | undefined,
      //#endregion Schedule Jobs

      // * DayPilot config
      config: {
        clientId: "",
        events: [] as ScheduleEvent[],
        resources: [] as ScheduleResource[],

        cellWidthSpec: "Fixed",
        cellWidth: 70,
        eventHeight: 70,
        // rowMinHeight: 60,

        timeHeaders: [
          { groupBy: "Day", format: "dddd, d MMMM yyyy" },
          { groupBy: "Hour" },
          { groupBy: "Cell", format: "mm" }
        ],
        scale: "CellDuration",
        cellDuration: 30,
        days: 1,
        businessWeekends: true,
        businessBeginsHour: 0,
        businessEndsHour: 24,

        startDate: DayPilot.Date.today(),

        showNonBusiness: true,
        timeRangeSelectedHandling: "Enabled",
        timeRangeSelectingStartEndEnabled: true,
        timeRangeSelectingStartEndFormat: "h:mm tt",
        onTimeRangeSelect: async (args) => {
          const { start, end, resource } = args;
          const self = this as any;

          // Populate drivers on first time range selection
          self.drivers ??= await JobsService.getAllDrivers(self.currentUser.clientId);

          const driver = (self.drivers as IDriver[]).find((x) => x.UserId === resource);

          self.selectedDriverDateRange = {
            startDate: start.value,
            endDate: end.value,
            driver: driver
          } as JobSchedulerSelection;

          self.showJobDialog = true;
        },

        /**
         * Event handler for when the user drags and drops an event on the scheduler.
         */
        eventMoveHandling: "Update",
        onEventMoved: (args: any) => {
          // Event metadata
          const eventData = args.e.data;
          const previousResource = eventData.previousResource;
          const resource = eventData.resource;
          const clientId = eventData.clientId;
          const job = eventData.job;

          // Build deliveryDate and endDate
          const deliveryDate = moment(eventData.start.value).toDate();
          const estimateEndDate = moment(eventData.end.value).toDate();

          // Build drivers and driverIds
          JobsService.getDriver(clientId, eventData.resource)
            .then((user: IUser) => {
              const driver: IDriver = {
                FirstName: user.FirstName,
                LastName: user.LastName,
                Status: "Sent",
                UserId: user.UserId!
              };

              // Drivers and DriverIds
              let drivers = job.Drivers;
              let driverIds = job.DriverIds;

              // Remove previousResource from array of Drivers and DriverIds,
              // and replace with the new resource
              drivers.forEach((element) => {
                if (element.UserId === previousResource) {
                  job.Drivers.splice(job.Drivers.indexOf(element), 1);
                }
              });

              driverIds.forEach((element) => {
                if (element === previousResource) {
                  job.DriverIds.splice(job.DriverIds.indexOf(element), 1);
                }
              });

              // Prevents assigning multiple copies of the same Driver to a Job if the same
              // Job event is dragged to a pre-assigned Driver of that Job.
              // ? Questions? Email nickb@webility.ca
              if (driverIds.indexOf(resource) !== -1) return;

              // Add new resource to the arrays.
              drivers.push(driver);
              driverIds.push(resource);

              // Build payload
              const payload: IJob = {
                DatabaseId: job.DatabaseId,
                Material: job.Material ?? null,
                Quantity: job.Quantity ?? null,
                Applications: job.Applications ?? null,
                JobType: job.JobType ?? null,
                Hourly: job.applicationIsHourly ?? null,
                Customer: job.Customer,
                CustomerContact: job.CustomerContact ?? null,
                JobSite: job.JobSite ?? null,
                Drivers: drivers,
                DriverIds: driverIds,
                DeliveryDetails: {
                  DeliveryDate: deliveryDate,
                  EstimateEndDate: estimateEndDate,
                  DeliveryDateType: job.DeliveryDetails?.DeliveryDateType ?? null,
                  Price: job.DeliveryDetails?.Price ?? null,
                  PriceType: job.DeliveryDetails?.PriceType ?? null,
                  IsTaxable: job.DeliveryDetails?.IsTaxable ?? false,
                  Notes: job.DeliveryDetails?.Notes ?? null
                },
                Completed: job.Completed,
                Images: job.Images ?? [],
                Created: new Date(),
                QuantityType: job.QuantityType,
                SortIndex: 0,
                DriverNotes: job.DriverNotes ?? null,
                AssignedQuarries: job.AssignedQuarries ?? null, // This may need to be null, see line history
                IsPhoneHiddenToDriver: job.IsPhoneHiddenToDriver,
                Canceled: job.Canceled
              };

              // Update Job to db
              JobsService.updateJob(clientId, payload)
                .then(() => {
                  // Refresh activeJobs
                  JobsService.getAllActiveJobs(clientId).then(() => {
                    store.dispatch("SET_UPDATE_DASHBOARD", true);
                  });
                })
                .catch((e: any) => {
                  // Toast error
                  error({
                    title: "Error",
                    text: `There was an error updating the Job: ${e}`,
                    hide: true,
                    closer: false,
                    sticker: false,
                    modules: new Map([
                      [
                        Confirm,
                        {
                          confirm: true,
                          buttons: [
                            {
                              text: "Ok",
                              primary: true,
                              click: (notice: any) => notice.close()
                            }
                          ]
                        }
                      ]
                    ])
                  });
                });
            })
            .catch((e: any) => {
              // Toast error
              error({
                title: "Error",
                text: `There was an error updating the Job: ${e}`,
                hide: true,
                closer: false,
                sticker: false,
                modules: new Map([
                  [
                    Confirm,
                    {
                      confirm: true,
                      buttons: [
                        {
                          text: "Ok",
                          primary: true,
                          click: (notice: any) => notice.close()
                        }
                      ]
                    }
                  ]
                ])
              });
            });
        },

        /**
         * Event handler for when a user resizes an event on the schedule.
         */
        eventResizeHandling: "Update",
        onEventResized: (args: any) => {
          // Event metadata
          const eventData = args.e.data;
          const clientId = eventData.clientId;
          const job = eventData.job;

          // Build deliveryDate and endDate
          const deliveryDate = moment(eventData.start.value).toDate();
          const estimateEndDate = moment(eventData.end.value).toDate();

          // Build drivers and driverIds
          JobsService.getDriver(clientId, eventData.resource)
            .then(() => {
              // Drivers and DriverIds
              let drivers = job.Drivers;
              let driverIds = job.DriverIds;

              // Build payload
              const payload: IJob = {
                DatabaseId: job.DatabaseId,
                Material: job.Material ?? null,
                Quantity: job.Quantity ?? null,
                Applications: job.Applications ?? null,
                JobType: job.JobType ?? null,
                Hourly: job.applicationIsHourly ?? null,
                Customer: job.Customer,
                CustomerContact: job.CustomerContact ?? null,
                JobSite: job.JobSite ?? null,
                Drivers: drivers,
                DriverIds: driverIds,
                DeliveryDetails: {
                  DeliveryDate: deliveryDate,
                  EstimateEndDate: estimateEndDate,
                  DeliveryDateType: job.DeliveryDetails?.DeliveryDateType ?? null,
                  Price: job.DeliveryDetails?.Price ?? null,
                  PriceType: job.DeliveryDetails?.PriceType ?? null,
                  IsTaxable: job.DeliveryDetails?.IsTaxable ?? false,
                  Notes: job.DeliveryDetails?.Notes ?? null
                },
                Completed: job.Completed,
                Images: job.Images ?? [],
                Created: new Date(),
                QuantityType: job.QuantityType,
                SortIndex: 0,
                DriverNotes: job.DriverNotes ?? null,
                AssignedQuarries: job.AssignedQuarries ?? null,
                IsPhoneHiddenToDriver: job.IsPhoneHiddenToDriver,
                Canceled: job.Canceled
              };

              // Update Job to db
              JobsService.updateJob(clientId, payload)
                .then(() => {
                  // Refresh activeJobs
                  JobsService.getAllActiveJobs(clientId).then(() => {
                    store.dispatch("SET_UPDATE_DASHBOARD", true);
                  });
                })
                .catch((e: any) => {
                  // Toast error
                  error({
                    title: "Error",
                    text: `There was an error updating the Job: ${e}`,
                    hide: true,
                    closer: false,
                    sticker: false,
                    modules: new Map([
                      [
                        Confirm,
                        {
                          confirm: true,
                          buttons: [
                            {
                              text: "Ok",
                              primary: true,
                              click: (notice: any) => notice.close()
                            }
                          ]
                        }
                      ]
                    ])
                  });
                });
            })
            .catch((e: any) => {
              // Toast error
              error({
                title: "Error",
                text: `There was an error updating the Job: ${e}`,
                hide: true,
                closer: false,
                sticker: false,
                modules: new Map([
                  [
                    Confirm,
                    {
                      confirm: true,
                      buttons: [
                        {
                          text: "Ok",
                          primary: true,
                          click: (notice: any) => notice.close()
                        }
                      ]
                    }
                  ]
                ])
              });
            });
        },

        eventDeleteHandling: "Disabled",

        eventClickHandling: "Enabled",
        onEventClick: async (args: any) => {
          // Event metadata
          const eventData = args.e.data;

          const self = this as any;

          // Populate drivers on first time range selection
          self.drivers ??= await JobsService.getAllDrivers(self.currentUser.clientId);

          self.selectedJob = eventData.job as IJob;
          self.showJobDialog = true;
        },

        eventHoverHandling: "Bubble"
      }
    };
  },

  mounted() {
    // Pass clientId to DayPilot config
    this.config.clientId = this.currentUser.clientId;
    this.getJobs(new Date().toISOString());
  },

  methods: {
    getJobs(date: string): void {
      this.isLoading = true;
      this.lastUsedDate = date;

      const start = new Date(new Date(date).setHours(0, 0, 0, 0));
      const end = new Date(new Date(date).setHours(23, 59, 59));

      JobsService.getJobsDateRange(this.currentUser.clientId, start, end, true, true, false)
        .then((data: IJob[]) => {
          if (!data) return;

          this.jobsData = data;
          this.getEvents();
          this.getResources();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    /**
     * Generates ScheduleEvents for the DayPilot Scheduler, using Jobs from state
     * @author Nick Brahimir
     */
    getEvents(): void {
      const scheduleEvents: ScheduleEvent[] = [];

      this.jobsData.forEach((element: IJob) => {
        // ! Add utcoffset hours here
        // ? x1000 converts the UNIX timestamp
        const momentStartDate = moment(new Date(element.DeliveryDetails?.DeliveryDate.seconds * 1000));
        const utcOffset = Math.abs(momentStartDate.utcOffset() / 60);
        const start = moment(new Date(element.DeliveryDetails?.DeliveryDate.seconds * 1000))
          .subtract(utcOffset, "hours")
          .toISOString();

        let end: string;

        // Default EstimateEndDate
        end = moment(new Date(element.DeliveryDetails?.DeliveryDate.seconds * 1000))
          .add(1, "hours")
          .subtract(utcOffset, "hours")
          .toISOString();

        // If the Job HAS an EstimateEndDate, set the ScheduleEvent "end" here.
        if (element.DeliveryDetails?.EstimateEndDate) {
          end = moment(new Date(element.DeliveryDetails?.EstimateEndDate.seconds * 1000))
            .subtract(utcOffset, "hours")
            .toISOString();
        }

        if (element.Drivers?.length) {
          element.Drivers.forEach((driver) => {
            const index = element.Drivers?.indexOf(driver);

            // Build the Event html
            const html = this.buildEventHtml(element);

            // Build the on-hover Event bubbleHtml
            const bubbleHtml = this.buildEventBubbleHtml(element);

            let barColor;
            let backColor;

            // Check if Job is Completed or isOverdueJob() and color Event bars accordingly
            if (element.Completed) {
              barColor = "#008000";
              backColor = "#acf8ac";
            }
            // ! Add utcoffset hours here for the comparison.
            else if (this.isOverdueJob(moment(start).add(utcOffset, "hours").toISOString())) {
              barColor = "#a90000";
              backColor = "#ffd1d1";
            } else {
              barColor = "#1167a8";
              backColor = "#f6f6f6";
            }

            // Build the Schedule Event object
            let event: ScheduleEvent = {
              id: element.DatabaseId! + index,
              start: start,
              end: end,
              previousResource: driver.UserId,
              resource: driver.UserId,
              html: html,
              job: element,
              clientId: this.currentUser.clientId,
              bubbleHtml: bubbleHtml,

              barColor: barColor,
              backColor: backColor
            };

            scheduleEvents.push(event);
          });
        }
      });

      this.config.events = scheduleEvents;
    },

    /**
     * Generates ScheduleResources for the DayPilot Scheduler, by fetching Drivers
     * @author Nick Brahimir
     */
    getResources(): void {
      JobsService.getAllDrivers(this.currentUser.clientId).then((data: IDriver[]) => {
        if (!data) return;

        // Resize Scheduler cells if there are few Drivers.
        if (data.length <= 3) {
          this.config.cellWidth = 100;
          this.config.eventHeight = 100;
        }

        const scheduleResources: ScheduleResource[] = [];

        data.forEach((driver: IDriver) => {
          scheduleResources.push({
            id: driver.UserId!,
            name: `${driver.FirstName} ${driver.LastName}`
          });
        });

        this.config.resources = scheduleResources;
      });
    },

    buildEventHtml(element: IJob): string {
      let eventHtml = `<div>`;

      // Add Customer Name
      if (element.Customer.Name) {
        eventHtml += `<b>${element.Customer.Name}</b> <br/ >`;
      }

      // Add Jobsite Name/Address
      if (element.JobSite) {
        // If Jobsite has a Name, show it
        if (element.JobSite.Name) eventHtml += `${element.JobSite.Name} <br/ >`;

        // If Jobsite does NOT have a name, show it's Address if it has one
        if (!element.JobSite.Name && element.JobSite.StreetAddress)
          eventHtml += `${element.JobSite.StreetAddress} <br/ >`;
      }

      // Add Job event metadata to display
      if (element.Material) if (element.Material.Name) eventHtml += `${element.Material.Name} <br/ >`;
      if (element.Quantity && element.QuantityType)
        if (element.Quantity.Name)
          eventHtml += `${element.Quantity.Name} ${element.QuantityType} <br/ >`;

      eventHtml += `</div>`;

      return eventHtml;
    },

    buildEventBubbleHtml(element: IJob): string {
      let eventStatus;
      if (element.Completed) {
        eventStatus = `<b style='color: green'>Complete</b>`;
      } else {
        eventStatus = `<b style='color: red'>Incomplete</b>`;
      }

      let bubbleHtml = `
        <h6>${element.Customer.Name}</h6>
        ${eventStatus}
      `;

      if (element.Material || element.Applications.length || element.Quantity) {
        bubbleHtml += `<hr class='my-2' />`;

        if (element.Material)
          if (element.Material.Name) bubbleHtml += `<b>Material:</b> ${element.Material.Name} <br />`;

        if (element.Quantity)
          if (element.Quantity.Name)
            bubbleHtml += `<b>Quantity:</b> ${element.Quantity.Name} ${element.QuantityType} <br />`;

        if (element.Applications.length) {
          let applications = "";
          element.Applications.forEach((application: IApplication) => {
            const index = element.Applications.indexOf(application);

            applications += application.Name;
            if (index !== element.Applications.length - 1) applications += ", ";
          });

          bubbleHtml += `<b>Applications:</b> ${applications} <br />`;
        }
      }

      if (element.JobSiteDetails) {
        bubbleHtml += `<hr class='my-2' />`;
        if (element.JobSiteDetails.Lot) bubbleHtml += `<b>Lot:</b> ${element.JobSiteDetails.Lot} <br />`;

        if (element.JobSiteDetails.Block)
          bubbleHtml += `<b>Block:</b> ${element.JobSiteDetails.Block} <br />`;

        if (element.JobSiteDetails.Unit)
          bubbleHtml += `<b>Unit:</b> ${element.JobSiteDetails.Unit} <br />`;

        if (element.JobSiteDetails.Municipal)
          bubbleHtml += `<b>Municipal:</b> ${element.JobSiteDetails.Municipal} <br />`;

        if (element.JobSiteDetails.PO) bubbleHtml += `<b>PO:</b> ${element.JobSiteDetails.PO} <br />`;
      }

      if (element.DeliveryDetails) {
        bubbleHtml += `<hr class='my-2' />`;
        if (element.DeliveryDetails.Price)
          bubbleHtml += `<b>Price:</b> $${element.DeliveryDetails.Price} <br />`;

        if (element.DeliveryDetails.PriceType)
          bubbleHtml += `<b>Price Type:</b> ${element.DeliveryDetails.PriceType} <br />`;
      }

      return bubbleHtml;
    },

    isOverdueJob(date: any): boolean {
      const testDate = moment(new Date(date));
      const currentDate = moment(new Date());

      return testDate < currentDate;
    },

    handleJobDialogClose() {
      this.selectedDriverDateRange = undefined;
      this.selectedJob = undefined;
      this.showJobDialog = false;
      (this.$refs.scheduler as any).clearSelection();
    }
  },

  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      activeJobs: "activeJobs"
    }),
    getCurrentOffset() {
      return Math.abs(moment().utcOffset() / 60);
    }
  },

  watch: {
    activeJobs(): void {
      this.getJobs(this.lastUsedDate);
    }
  }
});
