<template>
  <div id="scheduler">
    <v-row class="flex-between mx-1 mb-5">
      <div class="d-flex">
        <!-- start:: Today -->
        <v-btn outlined :color="isToday ? 'primary' : 'grey darken-2'" @click="today()">Today</v-btn>
        <!-- end:: Today -->

        <div class="mx-2"></div>

        <!-- start:: Previous Day -->
        <v-btn icon fab small @click="previous()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <!-- end:: Previous Day -->

        <!-- start:: Next Day -->
        <v-btn icon fab small @click="next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <!-- end:: Next Day -->
      </div>

      <div>
        <v-select
          v-model="selectedTimeInterval"
          :items="timeIntervals"
          label="Time interval"
          @change="updateTimeIntervals()"
          item-text="text"
          :hide-details="true"
          dense
          outlined
        ></v-select>
      </div>
    </v-row>

    <DayPilotScheduler id="dp" :config="config" ref="scheduler" />
  </div>
</template>

<script>
// Vue
import Vue from "vue";
import { mapGetters } from "vuex";
// DayPilot
import { DayPilot, DayPilotScheduler } from "daypilot-pro-vue";
// moment.js
import moment from "moment";
import AdminService from "@/views/admin/Admin.service";

export default Vue.extend({
  name: "Scheduler",
  props: {
    config: Object
  },
  components: { DayPilotScheduler },

  data() {
    return {
      intervalTimeMarker: {},

      timeIntervals: [
        { text: "15 minute", value: 15 },
        { text: "30 minute", value: 30 },
        { text: "60 minute", value: 60 }
      ],
      selectedTimeInterval: 30
    };
  },

  mounted() {
    this.init();
  },

  destroyed() {
    clearInterval(this.intervalTimeMarker);
  },

  methods: {
    init() {
      this.selectedTimeInterval = this.currentUser.client.JobSchedulerTimeIntervals;
      Vue.set(this.config, "cellDuration", this.selectedTimeInterval);
      this.scheduler.update();

      this.schedulerTimeIndicator();

      Vue.nextTick(() => {
        this.scrollToTimeToday(7);
      });
    },

    /**
     * Handles and calculates the "red" time indicator on the scheduler.
     */
    schedulerTimeIndicator() {
      // Red "time indicator" on the Scheduler
      const markerDate = new DayPilot.Date(
        moment(new Date()).subtract(this.getCurrentOffset(), "hours").toDate().toISOString()
      );
      this.scheduler.separators = [{ color: "red", location: markerDate }];

      this.intervalTimeMarker = setInterval(() => {
        const markerDate = new DayPilot.Date(
          moment(new Date()).subtract(this.getCurrentOffset(), "hours").toDate().toISOString()
        );

        this.scheduler.update({
          separators: [{ color: "red", location: markerDate }]
        });
      }, 60000);
    },

    scrollToTimeToday(hour) {
      const dateTime = DayPilot.Date.today().addHours(hour);

      this.scheduler.scrollTo(dateTime);
    },

    async updateTimeIntervals() {
      // Build payload
      const payload = {
        DocumentName: this.currentUser.client.DocumentName,
        Name: this.currentUser.client.Name,
        CompanyLogo: this.currentUser.client.CompanyLogo,
        MainThemeColor: this.currentUser.client.MainThemeColor,
        MainThemeTextColor: this.currentUser.client.MainThemeTextColor,
        JobSchedulerTimeIntervals: this.selectedTimeInterval
      };

      await AdminService.updateClient(this.currentUser.clientId, payload);
      this.$store.dispatch("SET_JOBSCHEDULER_TIME_INTERVALS", this.selectedTimeInterval);

      Vue.set(this.config, "cellDuration", this.selectedTimeInterval);
      this.scheduler.update();
    },

    /**
     * Handles Date navigation in the Scheduler
     * @author Nick Brahimir
     */
    previous() {
      let addDays = -1;
      if (this.isWorkingDaysSet) {
        addDays = getDaysToPreviousWorkDay.call(this);
      }

      const dpNewDate = this.config.startDate.addDays(addDays);

      Vue.set(this.config, "startDate", dpNewDate);
      this.$emit("get-jobs", dpNewDate.value);
      this.scheduler.update();

      function getDaysToPreviousWorkDay() {
        const currentWeekDay = this.config.startDate.dayOfWeek();
        let addDays;

        let nextWeekDay = this.sortedWorkingDays.findLast((x) => x < currentWeekDay);
        if (nextWeekDay) {
          addDays = nextWeekDay - currentWeekDay;
        } else {
          nextWeekDay = this.sortedWorkingDays[this.sortedWorkingDays.length - 1];
          addDays = -currentWeekDay + nextWeekDay - 7;
        }

        return addDays;
      }
    },

    /**
     * Handles Date navigation in the Scheduler
     * @author Nick Brahimir
     */
    today() {
      if (this.isToday) return;

      // ! Offset
      const dpNewDate = DayPilot.Date(
        moment(new Date()).subtract(this.getCurrentOffset(), "hours").toDate().toISOString()
      );

      Vue.set(this.config, "startDate", DayPilot.Date(dpNewDate));
      this.$emit("get-jobs", dpNewDate.value);
      this.scheduler.update();
    },

    /**
     * Handles Date navigation in the Scheduler
     * @author Nick Brahimir
     */
    next() {
      let addDays = 1;
      if (this.isWorkingDaysSet) {
        addDays = getDaysToNextWorkDay.call(this);
      }

      const dpNewDate = this.config.startDate.addDays(addDays);

      Vue.set(this.config, "startDate", dpNewDate);
      this.$emit("get-jobs", dpNewDate.value);
      this.scheduler.update();

      function getDaysToNextWorkDay() {
        const currentWeekDay = this.config.startDate.dayOfWeek();
        let addDays;

        let nextWeekDay = this.sortedWorkingDays.find((x) => x > currentWeekDay);
        if (nextWeekDay) {
          addDays = nextWeekDay - currentWeekDay;
        } else {
          nextWeekDay = this.sortedWorkingDays[0];
          addDays = nextWeekDay - currentWeekDay + 7;
        }

        return addDays;
      }
    },

    getCurrentOffset() {
      return Math.abs(moment().utcOffset() / 60);
    },

    clearSelection() {
      this.scheduler.clearSelection();
    }
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" }),

    // DayPilot.Scheduler object - https://api.daypilot.org/daypilot-scheduler-class/
    scheduler() {
      return this.$refs.scheduler.control;
    },

    /**
     * Determines if the Scheduler's current Date matches today's Date.
     * @returns True if the Scheduler's current Date matches today's Date, False otherwise.
     * @author Nick Brahimir
     */
    isToday() {
      const dateScheduler = new Date(this.config.startDate.value);
      const dateToday = new Date();

      return (
        dateScheduler.getFullYear() === dateToday.getFullYear() &&
        dateScheduler.getMonth() === dateToday.getMonth() &&
        dateScheduler.getDate() === dateToday.getDate()
      );
    },

    isWorkingDaysSet() {
      return this.currentUser.client.WorkingDays?.length > 0;
    },

    sortedWorkingDays() {
      return [...this.currentUser.client.WorkingDays].sort();
    }
  }
});
</script>

<style lang="scss" scoped>
.action {
  width: 9rem;
}
</style>
